<template>
        <div class="forgot-container">
            <h1 class="forgot-title">Changement de mot de passe réussi 🥳</h1>
            <p class="forgot-text">Vous pouvez vous connectez sans soucis maintenant !</p>
        </div>
</template>

<script setup>
export default {
    name: "FinalForgot",
}
</script>