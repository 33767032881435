<template>
	<div class="privacy">
		<main>
			<h1>Politique de confidentialité</h1>
			<h2>D&eacute;finitions :</h2>
			<p>L&#39;&Eacute;diteur : La personne, physique ou morale, qui &eacute;dite les services de communication au public en ligne.</p>
			<p>Le Site : L&#39;ensemble des sites, pages Internet et services en ligne propos&eacute;s par l&#39;&Eacute;diteur.</p>
			<p>L&#39;Utilisateur : La personne utilisant le Site et les services.</p>
			<h2>Nature des donn&eacute;es collect&eacute;es</h2>
			<p>Dans le cadre de l&#39;utilisation des Sites, l&#39;&Eacute;diteur est susceptible de collecter les cat&eacute;gories de donn&eacute;es suivantes concernant ses Utilisateurs :</p>
			<p>Donn&eacute;es d&#39;&eacute;tat-civil, d&#39;identit&eacute;, d&#39;identification...</p>
			<p>Donn&eacute;es de connexion (adresses IP, journaux d&#39;&eacute;v&eacute;nements...)</p>
			<p>Donn&eacute;es de localisation (d&eacute;placements, donn&eacute;es GPS, GSM...)</p>
			<h2>Communication des donn&eacute;es personnelles &agrave; des tiers</h2>
			<p><strong>Pas de communication &agrave; des tiers</strong></p>
			<p>Vos donn&eacute;es ne font l&#39;objet d&#39;aucune communication &agrave; des tiers. Vous &ecirc;tes toutefois inform&eacute;s qu&#39;elles pourront &ecirc;tre divulgu&eacute;es en application d&#39;une loi, d&#39;un r&egrave;glement ou en vertu d&#39;une d&eacute;cision d&#39;une autorit&eacute; r&eacute;glementaire ou judiciaire comp&eacute;tente.</p>
			<h2>Information pr&eacute;alable pour la communication des donn&eacute;es personnelles &agrave; des tiers en cas de fusion / absorption</h2>
			<p>Collecte de l&rsquo;opt-in (consentement) pr&eacute;alable &agrave; la transmission des donn&eacute;es suite &agrave; une fusion / acquisition</p>
			<p>Dans le cas o&ugrave; nous prendrions part &agrave; une op&eacute;ration de fusion, d&rsquo;acquisition ou &agrave; toute autre forme de cession d&rsquo;actifs, nous nous engageons &agrave; obtenir votre consentement pr&eacute;alable &agrave; la transmission de vos donn&eacute;es personnelles et &agrave; maintenir le niveau de confidentialit&eacute; de vos donn&eacute;es personnelles auquel vous avez consenti.</p>
			<h2>Finalit&eacute; de la r&eacute;utilisation des donn&eacute;es personnelles collect&eacute;es</h2>
			<p>La gestion des demandes de droit d&#39;acc&egrave;s, de rectification et d&#39;opposition</p>
			<h2>Agr&eacute;gation des donn&eacute;es</h2>
			<p><strong>Agr&eacute;gation avec des donn&eacute;es non personnelles</strong></p>
			<p>
				Nous pouvons publier, divulguer et utiliser les informations agr&eacute;g&eacute;es (informations relatives &agrave; tous nos Utilisateurs ou &agrave; des groupes ou cat&eacute;gories sp&eacute;cifiques d&#39;Utilisateurs que nous combinons de mani&egrave;re &agrave; ce qu&#39;un Utilisateur individuel ne puisse plus &ecirc;tre identifi&eacute; ou mentionn&eacute;) et les informations non personnelles &agrave; des fins d&#39;analyse du secteur et du march&eacute;, de profilage d&eacute;mographique, &agrave; des fins promotionnelles et publicitaires et &agrave; d&#39;autres fins
				commerciales.
			</p>
			<h2>Agr&eacute;gation avec des donn&eacute;es personnelles disponibles sur les comptes sociaux de l&#39;Utilisateur</h2>
			<p>Si vous connectez votre compte &agrave; un compte d&rsquo;un autre service afin de faire des envois crois&eacute;s, ledit service pourra nous communiquer vos informations de profil, de connexion, ainsi que toute autre information dont vous avez autoris&eacute; la divulgation. Nous pouvons agr&eacute;ger les informations relatives &agrave; tous nos autres Utilisateurs, groupes, comptes, aux donn&eacute;es personnelles disponibles sur l&rsquo;Utilisateur.</p>
			<h2>Collecte des donn&eacute;es d&#39;identit&eacute;</h2>
			<p><strong>Consultation libre</strong></p>
			<p>La consultation du Site ne n&eacute;cessite pas d&#39;inscription ni d&#39;identification pr&eacute;alable. Elle peut s&#39;effectuer sans que vous ne communiquiez de donn&eacute;es nominatives vous concernant (nom, pr&eacute;nom, adresse, etc). Nous ne proc&eacute;dons &agrave; aucun enregistrement de donn&eacute;es nominatives pour la simple consultation du Site.</p>
			<h2>Collecte des donn&eacute;es d&#39;identification</h2>
			<p><strong>Utilisation de l&#39;identifiant de l&#39;utilisateur pour proposition de mise en relation et offres commerciales</strong></p>
			<p>
				Nous utilisons vos identifiants &eacute;lectroniques pour rechercher des relations pr&eacute;sentes par connexion, par adresse mail ou par services. Nous pouvons utiliser vos informations de contact pour permettre &agrave; d&#39;autres personnes de trouver votre compte, notamment via des services tiers et des applications clientes. Vous pouvez t&eacute;l&eacute;charger votre carnet d&#39;adresses afin que nous soyons en mesure de vous aider &agrave; trouver des connaissances sur notre r&eacute;seau ou pour permettre &agrave; d&#39;autres Utilisateurs de notre r&eacute;seau de
				vous trouver. Nous pouvons vous proposer des suggestions, &agrave; vous et &agrave; d&#39;autres Utilisateurs du r&eacute;seau, &agrave; partir des contacts import&eacute;s de votre carnet d&rsquo;adresses. Nous sommes susceptibles de travailler en partenariat avec des soci&eacute;t&eacute;s qui proposent des offres incitatives. Pour prendre en charge ce type de promotion et d&#39;offre incitative, nous sommes susceptibles de partager votre identifiant &eacute;lectronique.
			</p>
			<h2>Collecte des donn&eacute;es du terminal</h2>
			<p><strong>Collecte des donn&eacute;es de profilage et des donn&eacute;es techniques &agrave; des fins de fourniture du service</strong></p>
			<p>Certaines des donn&eacute;es techniques de votre appareil sont collect&eacute;es automatiquement par le Site. Ces informations incluent notamment votre adresse IP, fournisseur d&#39;acc&egrave;s &agrave; Internet, configuration mat&eacute;rielle, configuration logicielle, type et langue du navigateur... La collecte de ces donn&eacute;es est n&eacute;cessaire &agrave; la fourniture des services.</p>
			<h2>Collecte des donn&eacute;es techniques &agrave; des fins publicitaires, commerciales et statistiques</h2>
			<p>
				Les donn&eacute;es techniques de votre appareil sont automatiquement collect&eacute;es et enregistr&eacute;es par le Site, &agrave; des fins publicitaires, commerciales et statistiques. Ces informations nous aident &agrave; personnaliser et &agrave; am&eacute;liorer continuellement votre exp&eacute;rience sur notre Site. Nous ne collectons ni ne conservons aucune donn&eacute;e nominative (nom, pr&eacute;nom, adresse...) &eacute;ventuellement attach&eacute;e &agrave; une donn&eacute;e technique. Les donn&eacute;es collect&eacute;es sont susceptibles d&rsquo;&ecirc;tre revendues
				&agrave; des tiers.
			</p>
			<h2>Cookies</h2>
			<p><strong>Dur&eacute;e de conservation des cookies</strong></p>
			<p>Conform&eacute;ment aux recommandations de la CNIL, la dur&eacute;e maximale de conservation des cookies est de 13 mois au maximum apr&egrave;s leur premier d&eacute;p&ocirc;t dans le terminal de l&#39;Utilisateur, tout comme la dur&eacute;e de la validit&eacute; du consentement de l&rsquo;Utilisateur &agrave; l&rsquo;utilisation de ces cookies. La dur&eacute;e de vie des cookies n&rsquo;est pas prolong&eacute;e &agrave; chaque visite. Le consentement de l&rsquo;Utilisateur devra donc &ecirc;tre renouvel&eacute; &agrave; l&#39;issue de ce d&eacute;lai.</p>
			<h2>Finalit&eacute; cookies</h2>
			<p>Les cookies peuvent &ecirc;tre utilis&eacute;s pour des fins statistiques notamment pour optimiser les services rendus &agrave; l&#39;Utilisateur, &agrave; partir du traitement des informations concernant la fr&eacute;quence d&#39;acc&egrave;s, la personnalisation des pages ainsi que les op&eacute;rations r&eacute;alis&eacute;es et les informations consult&eacute;es.</p>
			<p>Vous &ecirc;tes inform&eacute; que l&#39;&Eacute;diteur est susceptible de d&eacute;poser des cookies sur votre terminal. Le cookie enregistre des informations relatives &agrave; la navigation sur le service (les pages que vous avez consult&eacute;es, la date et l&#39;heure de la consultation...) que nous pourrons lire lors de vos visites ult&eacute;rieures.</p>
			<h2>Opt-in pour le d&eacute;p&ocirc;t de cookies</h2>
			<p>Nous n&#39;utilisons pas de cookies. Si nous devions en utiliser &agrave; l&rsquo;avenir, vous en seriez inform&eacute; pr&eacute;alablement et auriez la possibilit&eacute; de d&eacute;sactiver ces cookies.</p>
			<h2>Conservation des donn&eacute;es techniques</h2>
			<p><strong>Dur&eacute;e de conservation des donn&eacute;es techniques</strong></p>
			<p>Les donn&eacute;es techniques sont conserv&eacute;es pour la dur&eacute;e strictement n&eacute;cessaire &agrave; la r&eacute;alisation des finalit&eacute;s vis&eacute;es ci-avant.</p>
			<h2>D&eacute;lai de conservation des donn&eacute;es personnelles et d&#39;anonymisation</h2>
			<p><strong>Conservation des donn&eacute;es pendant la dur&eacute;e de la relation contractuelle</strong></p>
			<p>Conform&eacute;ment &agrave; l&#39;article 6-5&deg; de la loi n&deg;78-17 du 6 janvier 1978 relative &agrave; l&#39;informatique, aux fichiers et aux libert&eacute;s, les donn&eacute;es &agrave; caract&egrave;re personnel faisant l&#39;objet d&#39;un traitement ne sont pas conserv&eacute;es au-del&agrave; du temps n&eacute;cessaire &agrave; l&#39;ex&eacute;cution des obligations d&eacute;finies lors de la conclusion du contrat ou de la dur&eacute;e pr&eacute;d&eacute;finie de la relation contractuelle.</p>
			<p><strong>Conservation des donn&eacute;es anonymis&eacute;es au del&agrave; de la relation contractuelle / apr&egrave;s la suppression du compte</strong></p>
			<p>Nous conservons les donn&eacute;es personnelles pour la dur&eacute;e strictement n&eacute;cessaire &agrave; la r&eacute;alisation des finalit&eacute;s d&eacute;crites dans les pr&eacute;sentes CGU. Au-del&agrave; de cette dur&eacute;e, elles seront anonymis&eacute;es et conserv&eacute;es &agrave; des fins exclusivement statistiques et ne donneront lieu &agrave; aucune exploitation, de quelque nature que ce soit.</p>
			<p><strong>Suppression des donn&eacute;es apr&egrave;s suppression du compte</strong></p>
			<p>
				Des moyens de purge de donn&eacute;es sont mis en place afin d&#39;en pr&eacute;voir la suppression effective d&egrave;s lors que la dur&eacute;e de conservation ou d&#39;archivage n&eacute;cessaire &agrave; l&#39;accomplissement des finalit&eacute;s d&eacute;termin&eacute;es ou impos&eacute;es est atteinte. Conform&eacute;ment &agrave; la loi n&deg;78-17 du 6 janvier 1978 relative &agrave; l&#39;informatique, aux fichiers et aux libert&eacute;s, vous disposez par ailleurs d&#39;un droit de suppression sur vos donn&eacute;es que vous pouvez exercer &agrave; tout moment en
				prenant contact avec l&#39;&Eacute;diteur.
			</p>
			<p><strong>Suppression des donn&eacute;es apr&egrave;s 3 ans d&#39;inactivit&eacute;</strong></p>
			<p>Pour des raisons de s&eacute;curit&eacute;, si vous ne vous &ecirc;tes pas authentifi&eacute; sur le Site pendant une p&eacute;riode de trois ans, vous recevrez un e-mail vous invitant &agrave; vous connecter dans les plus brefs d&eacute;lais, sans quoi vos donn&eacute;es seront supprim&eacute;es de nos bases de donn&eacute;es.</p>
			<h2>Suppression du compte</h2>
			<p><strong>Suppression du compte &agrave; la demande</strong></p>
			<p>L&#39;Utilisateur a la possibilit&eacute; de supprimer son Compte &agrave; tout moment, par simple demande &agrave; l&#39;&Eacute;diteur OU par le menu de suppression de Compte pr&eacute;sent dans les param&egrave;tres du Compte le cas &eacute;ch&eacute;ant.</p>
			<p><strong>Suppression du compte en cas de violation des CGU</strong></p>
			<p>En cas de violation d&#39;une ou de plusieurs dispositions des CGU ou de tout autre document incorpor&eacute; aux pr&eacute;sentes par r&eacute;f&eacute;rence, l&#39;&Eacute;diteur se r&eacute;serve le droit de mettre fin ou restreindre sans aucun avertissement pr&eacute;alable et &agrave; sa seule discr&eacute;tion, votre usage et acc&egrave;s aux services, &agrave; votre compte et &agrave; tous les Sites.</p>
			<h2>Indications en cas de faille de s&eacute;curit&eacute; d&eacute;cel&eacute;e par l&#39;&Eacute;diteur</h2>
			<p><strong>Information de l&#39;Utilisateur en cas de faille de s&eacute;curit&eacute;</strong></p>
			<p>
				Nous nous engageons &agrave; mettre en oeuvre toutes les mesures techniques et organisationnelles appropri&eacute;es afin de garantir un niveau de s&eacute;curit&eacute; adapt&eacute; au regard des risques d&#39;acc&egrave;s accidentels, non autoris&eacute;s ou ill&eacute;gaux, de divulgation, d&#39;alt&eacute;ration, de perte ou encore de destruction des donn&eacute;es personnelles vous concernant. Dans l&#39;&eacute;ventualit&eacute; o&ugrave; nous prendrions connaissance d&#39;un acc&egrave;s ill&eacute;gal aux donn&eacute;es personnelles vous concernant stock&eacute;es sur
				nos serveurs ou ceux de nos prestataires, ou d&#39;un acc&egrave;s non autoris&eacute; ayant pour cons&eacute;quence la r&eacute;alisation des risques identifi&eacute;s ci-dessus, nous nous engageons &agrave; :
			</p>
			<p>Vous notifier l&#39;incident dans les plus brefs d&eacute;lais ;</p>
			<p>Examiner les causes de l&#39;incident et vous en informer ;</p>
			<p>Prendre les mesures n&eacute;cessaires dans la limite du raisonnable afin d&#39;amoindrir les effets n&eacute;gatifs et pr&eacute;judices pouvant r&eacute;sulter dudit incident</p>
			<h2>Limitation de la responsabilit&eacute;</h2>
			<p>En aucun cas les engagements d&eacute;finis au point ci-dessus relatifs &agrave; la notification en cas de faille de s&eacute;curit&eacute; ne peuvent &ecirc;tre assimil&eacute;s &agrave; une quelconque reconnaissance de faute ou de responsabilit&eacute; quant &agrave; la survenance de l&#39;incident en question.</p>
			<h2>Transfert des donn&eacute;es personnelles &agrave; l&#39;&eacute;tranger</h2>
			<p>Pas de transfert en dehors de l&#39;Union europ&eacute;enne</p>
			<p>L&#39;&Eacute;diteur s&#39;engage &agrave; ne pas transf&eacute;rer les donn&eacute;es personnelles de ses Utilisateurs en dehors de l&#39;Union europ&eacute;enne.</p>
			<h2>Modification des CGU et de la politique de confidentialit&eacute;</h2>
			<p>En cas de modification des pr&eacute;sentes CGU, engagement de ne pas baisser le niveau de confidentialit&eacute; de mani&egrave;re substantielle sans l&#39;information pr&eacute;alable des personnes concern&eacute;es</p>
			<p>Nous nous engageons &agrave; vous informer en cas de modification substantielle des pr&eacute;sentes CGU, et &agrave; ne pas baisser le niveau de confidentialit&eacute; de vos donn&eacute;es de mani&egrave;re substantielle sans vous en informer et obtenir votre consentement.</p>
			<h2>Droit applicable et modalit&eacute;s de recours</h2>
			<p><strong>Application du droit fran&ccedil;ais (l&eacute;gislation CNIL) et comp&eacute;tence des tribunaux</strong></p>
			<p>
				Les pr&eacute;sentes CGU et votre utilisation du Site sont r&eacute;gies et interpr&eacute;t&eacute;es conform&eacute;ment aux lois de France, et notamment &agrave; la Loi n&deg; 78-17 du 6 janvier 1978 relative &agrave; l&#39;informatique, aux fichiers et aux libert&eacute;s. Le choix de la loi applicable ne porte pas atteinte &agrave; vos droits en tant que consommateur conform&eacute;ment &agrave; la loi applicable de votre lieu de r&eacute;sidence. Si vous &ecirc;tes un consommateur, vous et nous acceptons de se soumettre &agrave; la comp&eacute;tence non-exclusive des
				juridictions fran&ccedil;aises, ce qui signifie que vous pouvez engager une action relative aux pr&eacute;sentes CGU en France ou dans le pays de l&#39;UE dans lequel vous vivez. Si vous &ecirc;tes un professionnel, toutes les actions &agrave; notre encontre doivent &ecirc;tre engag&eacute;es devant une juridiction en France.
			</p>
			<p>En cas de litige, les parties chercheront une solution amiable avant toute action judiciaire. En cas d&#39;&eacute;chec de ces tentatives, toutes contestations &agrave; la validit&eacute;, l&#39;interpr&eacute;tation et / ou l&#39;ex&eacute;cution des pr&eacute;sentes CGU devront &ecirc;tre port&eacute;es m&ecirc;me en cas de pluralit&eacute; des d&eacute;fendeurs ou d&#39;appel en garantie, devant les tribunaux fran&ccedil;ais.</p>
			<h2>Portabilit&eacute; des donn&eacute;es</h2>
			<p>L&#39;&Eacute;diteur s&#39;engage &agrave; vous offrir la possibilit&eacute; de vous faire restituer l&#39;ensemble des donn&eacute;es vous concernant sur simple demande. L&#39;Utilisateur se voit ainsi garantir une meilleure ma&icirc;trise de ses donn&eacute;es, et garde la possibilit&eacute; de les r&eacute;utiliser. Ces donn&eacute;es devront &ecirc;tre fournies dans un format ouvert et ais&eacute;ment r&eacute;utilisable.</p>
		</main>
		<Contact />
	</div>
</template>

<script>
import Contact from "../components/Contact";

export default {
	name: "Agreement",
	components: {
		Contact
	},
};
</script>

<style lang="scss">
.privacy {
	main {
		margin: 0 auto;
		max-width: 800px;
		@media (max-width: 720px) {
			padding: 0 1.5rem;	
		}
		h1 {
			margin-top: 4rem;
			font-size: 3rem;
			@media (max-width: 740px) {
				font-size: 2.4rem;
			}
		}
		h2 {
			margin-top: 2.5rem;
			display: inline-block;
			background: linear-gradient(0deg, var(--main-clr) 0%, var(--main-clr) 49%, rgba(110, 81, 190, 0) 50%);
		}
		h3 {
			margin-top: 2rem;
		}
		p {
			margin-top: 1.875rem;
			font-size: 1.1rem;
		}
		span[style*="underline"] {
			text-decoration: none !important;
			border-bottom: 4px dashed var(--main-clr);
		}
		em {
			color: var(--main-clr);
		}
		ol,
		ul {
			margin-top: 1.875rem;
		}
		ol > li::marker {
			font-size: 1.2rem;
		}
	}
}
</style>
